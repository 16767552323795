var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-list"},[_c('b-container',{staticClass:"products-section--margin"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"products-section__title"},[_c('h3',{staticClass:"products-section__title--style"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('h4',{staticClass:"products-section__subtitle--style"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")])]),_c('div',{staticClass:"products-section__list d-flex flex-wrap flew-row justify-content-around px-4"},[_c('product-item',{attrs:{"productID":"1","image":"compresor-1.png","title":"M17","description":"Para una rompedora o un topo.","items":[
                            { message: 'flujo: 60 – 35 cfm' }, 
                            { message: 'presión de trabajo: 100 – 217 psi' } ]}}),_c('product-item',{attrs:{"productID":"2","image":"compresor-2.png","title":"M27PE","description":"Para trabajar con dos rompedoras.","items":[
                            { message: 'flujo: 92 cfm' }, 
                            { message: 'presión de trabajo: 100 psi' } ]}}),_c('product-item',{attrs:{"productID":"3","image":"compresor-3.png","title":"M100","description":"Ideal para diferentes tipos de blasting.","items":[
                            { message: 'flujo: 375 – 225 cfm' }, 
                            { message: 'presión de trabajo: 100 – 200 psi' } ]}}),_c('product-item',{attrs:{"productID":"4","image":"compresor-4.png","title":"M200","description":"Para perforación y blasting.","items":[
                            { message: 'flujo: 750 –515 cfm' }, 
                            { message: 'presión de trabajo: 100 - 200 psi' } ]}}),_c('product-item',{attrs:{"productID":"5","image":"compresor-5.png","title":"M500-2","description":"Nuestro buque insignia, el M500-2, reúne las ventajas de un compresor de tornillo rotativo\n                        seco (libre de aceite) de dos etapas con las de un compresor portátil (flujo: hasta 1600 cfm; presión de trabajo hasta 150 psi)."}}),_c('product-final',{attrs:{"productID":"¡CONOCE MÁS DE NUESTROS PRODUCTOS!","image":"compresores.png","text":"¡CONOCE MÁS DE NUESTROS PRODUCTOS!"}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }