<template>
  <div class="contact-section">
    <div  class="contact-section__bg-wrapper d-none d-lg-block">
      <div class="contact-section--bg"></div>
    </div>
    <div class="contact-section--bg d-lg-none"></div>
    <b-container>
      <b-row>
        <b-col cols="12" lg="7" xl="8">
          <div class="contact-text--margin d-flex flex-column align-items-center align-items-sm-start">
            <div class="contact-section__title">
              <h1 class="contact-section__title--style contact-section--text-color">
                {{ mainTitle }}
              </h1>
            </div>
            <div class="contact-section__par mt-2 mt-sm-4">
              <p class="contact-section__par--style contact-section--text-color">
                {{ mainText }}
              </p>
            </div>
            <ksr-carousel class="contact-carousel--margin contact-carousel-display"></ksr-carousel>
          </div>
        </b-col>
        <b-col class="d-flex justify-content-center" cols="12" lg="5" xl="4">
          <div class="contact-form--margin">
            <ksr-contact-form
              title="Contáctanos"
              subtitle="Nos comunicaremos contigo a la brevedad"
              button="Enviar"
              sentTitle="¡Gracias por tu preferencia!"
              sentText="Nos comunicaremos contigo a la brevedad"
            ></ksr-contact-form>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ContactForm from './ContactForm/ContactForm'

export default {
  name: 'ContactSection',
  props: {
    mainTitle: String, 
    mainText: String
    },
  components: {
    'ksr-contact-form': ContactForm,
    'ksr-carousel': () => import('./Carousel/Carousel'),
  }
}
</script>

<style scoped>
  /* Text classes */
  .contact-section__title--style{
    font-size: 24px;
    font-weight: 700;
    line-height: 156.19%;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.3s;
  }
  .contact-section__par--style{
    font-size: 16px;
    font-weight: 300;
    line-height: 220%;
    text-align: center;
    transition: all 0.3s;
  }
  .contact-section--text-color{
    color: var(--ksr-white);
  }
  /* Wrappers */
  .contact-section__title{
    width: 300px;
    transition: width 0.3s;
  }
  .contact-section__par{
    width: 275px;
    transition: width 0.3s;
  }
  /* Margins */
  .contact-text--margin{
    margin-top: 43px;
    transition: margin-top 0.3s;
  }
  .contact-carousel--margin{
    margin-top: 10px;
    transition: margin-top 0.3s;
  }
  .contact-form--margin{
    margin-top: 132px;
    transition: margin-top 0.3s;
  }
  .contact-section{
    padding-top: 85px;
  }
  /* Images */
  .contact-section--bg{
    width: 100%;
    height: 730px;
    position: absolute;
    top: 0%;
    background-position: 85% 0%;
    background-image: url('~@/assets/bg.svg');
    background-repeat: no-repeat;
    background-size: initial;
    transition: background-position 0.3s;
  }
  .contact-section__image--style{
    width: 290px;
    height: 200px;
    transition: width 0.3s, height 0.3s;
  }
  @media (min-width: 576px) {
    .contact-section__title--style{
      font-size: 38px;
      font-weight: 700;
      line-height: 135.69%;
      text-align: left;
      transition: all 0.3s;
    }
    .contact-section__par--style{
      font-size: 18px;
      line-height: 185%;
      text-align: left;
      text-justify: initial;
      transition: all 0.3s;
    }
    /* Wrappers */
    .contact-section__title{
      width: 100%;
      transition: width 0.3s;
    }
    .contact-section__par{
      width: 100%;
      transition: width 0.3s;
    }
    /* Margins */
    .contact-text--margin{
      margin-top: 60px;
      transition: margin-top 0.3s;
    }
    .contact-carousel--margin{
      margin-top: 0px;
      transition: margin-top 0.3s;
    }
    .contact-form--margin{
      margin-top: 90px;
      transition: margin-top 0.3s;
    }
    /* Images */
    .contact-section__image--style{
      width: 551px;
      height: 310px;
      transition: width 0.3s, height 0.3s;
    }
  }
  @media (min-width: 768px) {
    /* Wrappers */
    .contact-section__title{
      width: 100%;
      transition: width 0.3s;
    }
    .contact-section__par{
      width: 100%;
      transition: width 0.3s;
    }
    /* Images */
    .contact-section--bg{
      background-position: left top;
      transition: background-position 0.3s;
    }
  }
  @media (min-width: 992px) {
     /* Wrappers */
    .contact-section__title{
      width: 450px;
      transition: width 0.3s;
    }
    .contact-section__par{
      width: 480px;
      transition: width 0.3s;
    }
    /* Margins */
    .contact-carousel--margin{
      margin-top: 0px;
      transition: margin-top 0.3s;
    }
    .contact-form--margin{
      margin-top: 60px;
      transition: margin-top 0.3s;
    }
    /* Images */
    .contact-section--bg{
      background-position: -80% -50%;
      transition: background-position 0.3s;
    }
    .contact-carousel-display{
      display: none;
    }
  }
  @media (min-width: 1200px) {
    /* Wrappers */
    .contact-section__title{
      width: 630px;
      transition: width 0.3s;
    }
    .contact-section__par{
      width: 650px;
      transition: width 0.3s;
    }
    .contact-section__bg-wrapper{
      position: absolute; 
      width: 100%;
      padding-right: 480px;
    }
    /* Margins */
    .contact-carousel--margin{
      margin-top: 0px;
      transition: margin-top 0.3s;
    }
    /* Images */
    .contact-section--bg{
      width: 800px;
      position: relative;
      background-image: url('~@/assets/bg-lg.svg');
      margin-right: auto;
      margin-left: auto;
      background-position-y: -200%;
    }
    .contact-carousel-display{
      display: block;
    }
  }
</style>

