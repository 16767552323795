<template>
    <div itemscope itemtype="https://schema.org/Organization" class="header">
        <div class="header__wrapper header__wrapper--color">
            <b-container>
                <b-row>
                    <b-col cols="12">
                        <div class="header__inner-wrapper--margin d-flex flex-row align-items-center justify-content-center">
                            <div class="header__logo mt-2 mt-sm-0 mr-sm-auto">
                                <a href="#" itemprop="url">
                                    <img itemprop="logo" :src="require('@/assets/' + logo)" class="header__footer--wrapper" alt="Logo">
                                </a>
                            </div>
                            <div class="header__footer-text--wrapper header__footer-text--position header__footer-text--color d-flex flex-row align-items-center justify-content-center">
                                <div class="header__text header__text--border align-items-center d-none d-lg-flex">
                                    <p class="header__text--style header__text--color text-right mb-0 mr-2">
                                        {{ title }}
                                    </p>
                                </div>
                                <div class="header__text header__text--border">
                                    <p class="header__text--style header__text--color text-right mb-0 mx-2">
                                        {{ workDays }}
                                        <br>
                                        {{ workHours }}
                                    </p>
                                </div>
                                <div class="header__text mr-3">
                                    <p class="header__text--style header__text--color text-center mb-0 ml-2">
                                        <a href="#contact-form" v-smooth-scroll="smoothScroolConfig">{{ callToAction }}</a>
                                        <br>
                                        <a itemprop="telephone" :href="'tel:' + tel">{{ tel }}</a>
                                    </p>
                                </div>
                                <a :href="'tel:' + tel">
                                    <div class="header__tel">
                                        <div class="header__icon--circle d-flex justify-content-center mb-2">
                                            <font-awesome-icon :icon="icon" size="lg" class="header__icon-color my-auto"/>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Header', 
  props: {
    title: String, 
    logo: String,
    workDays: String,
    workHours: String,
    callToAction: String,
    number: String,
    tel: String,
    icon: String
    }, 
    data() {
        return {
            smoothScroolConfig: {   
                duration: 350, 
                offset: -85, 
                container: '', 
                updateHistory: true 
            }
        }
    }
}
</script>

<style scoped>
    /* Text */
    .header__text--style{
        font-size: 14px;
        font-weight: 500;
        transition: font-size 0.3s;
    }
    .header__text--color{
        color: var(--ksr-black);
    }
    .header__wrapper--color{
        background: var(--ksr-yellow);
    }
    /* Wrappers */
    .header__wrapper{
        width: 100%;
        height: 85px; 
        position: fixed;
        transition: height 0.3s;
        z-index: 2;
    }
    .header__text{
        height: 57px;
    }
    .header__footer--wrapper{
        width: 102px;
        height: 47px;
        transition: all 0.3s;
    }
    .header__icon-circle{
        width: 40px;
        height: 40px;
    }
    .header__footer-text--wrapper{
        width: 100%;
        height: 85px;
        transition: all 0.3s;
    }
    /*  Margins */
    .header__inner-wrapper--margin{
        margin-top: 14px;
    }
    /*  Styles */
    .header__text--border{
        border-right: 1px solid var(--ksr-black); 
    }
    .header__icon--circle{
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: var(--ksr-white);
        transition: all 0.3s;
    }
    .header__icon--circle:hover{
        background-color: var(--ksr-gray);
        transition: all 0.3s;
    }
    .header__icon-color{
        color: var(--ksr-black);
        transition: all 0.3s;
    }
    .header__icon--circle:hover .header__icon-color{
        color: var(--ksr-white);
        transition: all 0.3s;
    }
    .header__footer-text--position{
        position: fixed;
        bottom: 0%;
        z-index: 1;
        transition: position 0.3s;
    }
    .header__footer-text--color{
        background-color: var(--ksr-yellow);
    }
    a, a:hover{
        color: inherit;
    }
    @media (min-width: 576px){
        /* Text */
        .header__text--style{
            font-size: 18px;
            transition: font-size 0.3s;
        }
        /* Wrappers */
        .header__wrapper{
            height: 89px; 
            transition: height 0.3s;
        }
        .header__footer--wrapper{
            width: 164px;
            height: 71px;
            transition: all 0.3s;
        }
        .header__footer-text--wrapper{
            width: auto;
            height: 67px;
            transition: all 0.3s;
        }
        /* Styles */
        .header__icon--circle{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: var(--ksr-white);
            transition: all 0.3s;
        }
        .header__footer-text--position{
            position: relative;
            transition: position 0.3s;
        }
    }
</style>
                