<template>
    <div itemscope itemtype="http://schema.org/Property" class="icons-list">
        <b-container class="icons-list--margin">
            <b-row>
                <b-col cols="12">
                    <div class="d-flex flex-row flex-wrap align-items-center justify-content-around">
                        <icon-item
                            icon="gas-pump"
                            feature="Más aire comprimido por menos energía = menor consumo de diésel"
                        />
                        <icon-item
                            icon="check"
                            feature="Calidad alemana y tecnología de punta."
                        />
                        <icon-item
                            icon="clock"
                            feature="Atención de servicio técnico las 24 hrs."  
                        />
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

export default {
    name: 'IconsList', 
    props: {
        icon: String, 
        feature: String
    },
    components: {
        'icon-item': () => import('./IconItem')
    }
}
</script>

<style>
    .icons-list--margin{
        margin-top: 83px; 
        transition: margin-top 0.3s;
    }
    @media (min-width: 768px) {
        .icons-list--margin{
            margin-top: 160px;
            transition: margin-top 0.3s; 
        }
    }
</style>
