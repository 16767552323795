<template>
  <div id="app">
    <ksr-header
      logo="logo.svg"
      workDays="Lunes a Viernes"
      workHours="8:00 - 18:00"
      callToAction="Contáctanos"
      tel="(442) 218-6448"
      icon="phone"
      title="Atención a todo México"
    />
     <ksr-contact-section
      mainTitle="Aire comprimido móvil para condiciones extremas."
      mainText="Equipados con innovadores motores de bajo consumo y resistentes carrocerías, todos nuestros
      potentes compresores portátiles se fabrican en Alemania, desde el más pequeño hasta el más grande."
    />
    <ksr-certifications-section/>
    <ksr-product-list
      title="Catálogo"
      subtitle="Dentro de nuestro catálogo contamos con más de 20 modelos, entre ellos:"
    />
    <ksr-icon-list/>
    <ksr-air-service-section
      title="KAESER Air Service"
      subtitle="Un gran servicio de asistencia técnica 24/7 que reducirá significativamente costos de energía."
      text="El aire comprimido debe estar disponible en todo momento y para lograr una disponibilidad absoluta, existe
      este servicio de asistencia técnica con suministro de piezas disponible cualquier día de la semana, 24 horas al día."
      image="imagen-air.png"
    />
    <ksr-background-section/>
    <ksr-footer
      logo="logo.svg"
    />
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
  :root{
    --ksr-yellow: #FBB601;
    --ksr-white: #ffffff; 
    --ksr-black: #000000; 
    --ksr-gray: #848484; 
    --ksr-lightGray: #E8E8E8; 
    --ksr-iron: #4F4F4F; 
    --ksr-smoke: #F5F5F5; 
    --ksr-red: #E04A4A;
    --ksr-green: #52CF84;
  }
  #app{
    background-color: var(--ksr-white);
  }
  *{
    font-family: 'Roboto';
    font-style: normal;
  }
</style>
