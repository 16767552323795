<template>
    <div class="product-list">
        <b-container class="products-section--margin">
            <b-row>
                <b-col cols="12">
                    <div class="products-section__title">
                        <h3 class="products-section__title--style">
                            {{ title }}
                        </h3>
                        <h4 class="products-section__subtitle--style"> 
                            {{ subtitle }}
                        </h4>
                    </div>
                    <div class="products-section__list d-flex flex-wrap flew-row justify-content-around px-4">
                        <product-item
                            productID="1"
                            image="compresor-1.png"
                            title="M17"
                            description="Para una rompedora o un topo."
                            :items="[
                                { message: 'flujo: 60 – 35 cfm' }, 
                                { message: 'presión de trabajo: 100 – 217 psi' }, 
                            ]"
                        />
                        <product-item
                            productID="2"
                            image="compresor-2.png"
                            title="M27PE"
                            description="Para trabajar con dos rompedoras."
                            :items="[
                                { message: 'flujo: 92 cfm' }, 
                                { message: 'presión de trabajo: 100 psi' }, 
                            ]"
                        />
                        <product-item
                            productID="3"
                            image="compresor-3.png"
                            title="M100"
                            description="Ideal para diferentes tipos de blasting."
                            :items="[
                                { message: 'flujo: 375 – 225 cfm' }, 
                                { message: 'presión de trabajo: 100 – 200 psi' }, 
                            ]"
                        />
                        <product-item
                            productID="4"
                            image="compresor-4.png"
                            title="M200"
                            description="Para perforación y blasting."
                            :items="[
                                { message: 'flujo: 750 –515 cfm' }, 
                                { message: 'presión de trabajo: 100 - 200 psi' }, 
                            ]"
                        />
                        <product-item
                            productID="5"
                            image="compresor-5.png"
                            title="M500-2"
                            description="Nuestro buque insignia, el M500-2, reúne las ventajas de un compresor de tornillo rotativo
                            seco (libre de aceite) de dos etapas con las de un compresor portátil (flujo: hasta 1600 cfm; presión de trabajo hasta 150 psi)."
                        />
                        <product-final
                            productID="¡CONOCE MÁS DE NUESTROS PRODUCTOS!"
                            image="compresores.png"
                            text="¡CONOCE MÁS DE NUESTROS PRODUCTOS!"
                        />
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

export default {
    name: 'ProductList',
    props: {
        title: String, 
        subtitle: String
    },
    components: {
        'product-item': () => import('./ProductItem'),
        'product-final': () => import('./ProductFinal'),
    }
}
</script>

<style scoped>
    .products-section__title--style{
        font-size: 22px;
        font-weight: 400;
        text-align: center;
    }
    .products-section__subtitle--style{
        font-size: 16px;
        font-weight: 300;
        text-align: center;
    }
    .products-section--margin{
        margin-top: 113px;
    }
    @media (min-width: 1200px) {
        .products-section--margin{
            margin-top: 160px;
        }
    }
</style>